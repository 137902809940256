// extracted by mini-css-extract-plugin
export var Article = "style-module--Article---G9VA";
export var BackArrow = "style-module--BackArrow--JdlcQ";
export var Banner = "style-module--Banner--vyC10";
export var BannerCaption = "style-module--BannerCaption--8Q-kA";
export var BannerImage = "style-module--BannerImage--sgrag";
export var Body = "style-module--Body--SQdZK";
export var Breadcrumb = "style-module--Breadcrumb--RlgwQ";
export var Category = "style-module--Category--b3jzj";
export var Content = "style-module--Content--QZ83b";
export var Details = "style-module--Details--HCneO";
export var Footer = "style-module--Footer--mrB9Z";
export var Header = "style-module--Header--hiixk";
export var Keyword = "style-module--Keyword--jTndX";
export var ReadingTime = "style-module--ReadingTime--+xf97";